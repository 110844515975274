@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');

@font-face {
  font-family: candara;
  src: url("candara.ttf");
}
* {
  scroll-behavior: smooth;
}

:root {
  --background: #0c0f12;
  --primary-color: #fff;
  --accent-color: #df9b3b;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
img {
  user-select: none!important;
}
h1,h2,h3,h4,h5,h6{
  font-family: Lora;
  font-weight: 600!important;
}

body {
  overflow-x: hidden!important;
  scroll-behavior: smooth;
  background: var(--background);
  color: var(--primary-color);
  font-family: candara;
  font-display: auto;
}

.hide-800 {
  display: flex;
}

/* NAVIGATION */

.menu-button {
  position: fixed;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 30px;
  height: 30px;
  top: 2rem;
  right: 20px;
  z-index: 110;
  transition: 0.3s all ease-in-out;

}

.menu-button .line-top {
  position: relative;
  height: 2px;
  background: var(--primary-color);
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.menu-button .line-bottom {
  position: relative;
  height: 2px;
  background: var(--primary-color);
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.close-button .line-top {
  transform: rotate(45deg) translateY(2px);
}

.close-button .line-bottom {
  transform: rotate(-45deg) translateY(-1px);
}

.close-button {
  gap: 0px;
  transition: 0.3s all ease-in-out;
  z-index: 110;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.8px);
  -webkit-backdrop-filter: blur(12.8px);
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.65);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.1px);
  -webkit-backdrop-filter: blur(10.1px);
  z-index: -1;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}

.mobile-nav-inner {
  padding-top: 100px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

}

.divider {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.075);
}

.mobile-nav a {
  color: white;
  font-size: 20px;
  font-weight: 800;
}

.open-menu {
  opacity: 1;
  z-index: 100;
}

.social-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.social-mobile img {
  width: 20px;
}

nav {
  width: 1380px;
  max-width: 1380px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: auto;
  background: transparent;
  position: fixed;
  top: 0px;
  z-index: 100;
  left: 0;
  right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  background: transparent;
}

nav .link,
nav a {
  position: relative;
  color: var(--primary-color);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  padding-inline: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-inline: 10px;
  border-radius: 5px;
  text-decoration: none;
}

.link:hover,
nav a:hover {

  color: var(--accent-color);
  transition: 0.2s ease-in-out;
  cursor: pointer;
}


.linkactive {
  background: var(--accent-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
}

.mainlogo {
  width: 70px;
  height: 70px;
  z-index: 100;
  border-radius: 50%;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links {
  display: flex;
  align-items: center;
}

.social-icons img {
  width: 100%;
  height: 100%;
}
nav a{
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.397);
  border-radius: 5px;
  transition: 0.2s all ease-in-out;
  background: var(--background);
}
.logoa{
  padding: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
}
.social-icons a{
  width: 20px;
  height: 20px;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.397);
  border-radius: 5px;
  transition: 0.2s all ease-in-out;
  background: var(--background);
}

.social-icons a:hover, nav a:hover {
  border: 1px solid rgba(255, 255, 255, 1);
  background: var(--background);
}
.logoa:hover{
  background: transparent;
  border: 0;
}

@media(max-width: 1000px) {
  .menu-button {
    display: flex;
  }

  .mobile-nav a {
    text-decoration: none !important;
  }

  .mobile-nav a::after {
    display: none;
  }

  nav {
    top: 0px;
    padding-inline: 5px;
  }

  .hide-800 {
    display: none;
  }
}

/* NAVIGATION END */

.home-top {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  background: url(./home.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-attachment: fixed;
}
.home-top h2{
  color: var(--accent-color);
  font-size: 30px;
}

.label {
  color: var(--accent-color);
  padding: 8px 24px;
  border-radius: 5px;
  border: 1px solid var(--accent-color);
  opacity: 0.5;
}

.label h3 {
  margin: 0;
  font-weight: 600;
}
.smallermobi{
  background: var(--accent-color);
  color: black;
  opacity: 1;
}

.home-top h1 {
  width: 90vw;
  max-width: 1200px;
  font-size: 160px;
  text-align: center;
  background-image: radial-gradient(circle, rgba(121,72,16,1) 0%, rgba(233,201,43,1) 17%, rgba(121,72,16,1) 39%, rgba(121,72,16,1) 55%, rgba(227,151,41,1) 62%, rgba(144,75,16,1) 77%, rgb(248, 134, 27) 93%);
  -webkit-background-clip: text; /* Safari/Chrome */
  background-clip: text;
  color: transparent;
  text-fill-color: transparent;
  font-weight: 100;
  display: flex;
  cursor: default;
  margin-top: 0;
  margin-bottom: 0;
  justify-content: center;
  -webkit-text-stroke: 1px var(--accent-color);

}

.home-top h1 .letter:hover {
  color: var(--accent-color);
  cursor: default;
  transform: rotateZ(180deg);
}

.home-top h1 .letter {
  transition: all 0.2s ease-in-out;
}



.project,
.prologue,
.faq,
.team,
.discord-banner,
footer {
  padding: 75px 0;
  width: 1380px;
  max-width: 1380px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: relative;
}

.text-part .title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.text-part .title h2 {
  font-size: 100px;
  line-height: 100px;
  font-weight: 800;
  margin: 0;
  margin-left: -6px;
  color: var(--accent-color);
}
.title h4{
  font-size: 34px;
  line-height: 34px;
  font-weight: 800;
  margin: 0;
  margin-inline: auto;
  color: white;;
  text-align: center;
}
.timeline .title h4{
  font-family: candara;
}

.text-part .title h3 {
  font-size: 34px;
  line-height: 34px;
  font-weight: 800;
  margin: 0;
  color: #2a3036;
}

.project img,
.faq img, .team img, .prologue img {
  height: 500px;
  border-radius: 10px;
  width: calc(40% - 40px);
  object-fit: cover;

}
.project img, .team img{
  height: auto;
}
.project img, .faq img{
  width: 100%;
}
.imgteam{
  width: calc(50% - 40px);
  position: relative;

}
.fadeBot{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 2;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, var(--background) 80%);
}
.team img{
  border: none;
  box-shadow: none;
  width: 100%;
}
.team .text-part{
  width: calc(50% - 40px);
}


.text-part {
  width: calc(60% - 40px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.text-part hr {
  width: 40%;
  height: 1px;
  background: #2a3036;
  border: none;
  margin: 0;
  margin-bottom: 0px;
  margin-left: 6px;

}

.text-part p {
  font-size: 22px;
  margin: 60px 0;
}
.text-part p span{
  color: var(--accent-color);
}

.labels {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.info-label {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.315);
  padding: 8px 16px;
  border-radius: 5px;
}

.info-label em {
  color: var(--accent-color);
}

@media(max-width: 1480px) {

  .project,
  .prologue,
  .faq,
  .team,
  .discord-banner,
  footer {
    width: calc(100% - 200px);
    padding: 75px 0px;
  }

  nav {
    width: calc(100% - 200px);
  }

  .text-part .title h2 {
    font-size: 100px;
    line-height: 100px;
  }

}

.prologue-wrap,
.timeline {
  background: #101418;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.prologue {
  height: 600px;
}

.prologue {
  justify-content: flex-start;
  align-items: flex-start;
}

.faq {
  align-items: flex-start;
}



.faq img {
  height: 700px;
}

.faqq p {
  margin: 10px 0;
}

.MuiAccordion-root {
  background: transparent !important;
  width: 100%;
}

.MuiTypography-root {
  color: white !important;
  font-family: candara !important;
  font-weight: 600 !important;
  transition: 0.2s all ease;
}
.MuiButtonBase-root:hover .MuiTypography-root, .MuiButtonBase-root:hover .MuiSvgIcon-root
{
  color: var(--accent-color)!important;
}

.MuiTypography-root span {
  color: var(--accent-color) !important;
}

.MuiSvgIcon-root {
  color: var(--primary-color) !important;
}

.Mui-expanded .MuiAccordionSummary-root .MuiTypography-root {
  color: var(--accent-color) !important;
}

.Mui-expanded .MuiSvgIcon-root {
  color: var(--accent-color) !important;
}

.team-wrap .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.team-wrap .title h2 {
  font-size: 130px;
  line-height: 140px;
  font-weight: 800;
  margin: 0;
  margin-left: -6px;
  color: var(--accent-color);
}

@media(max-width: 1480px) {
  .team-wrap .title h2 {
    font-size: 140px;
    line-height: 100px;
  }
}

.team-wrap .title h3 {
  font-size: 34px;
  line-height: 34px;
  font-weight: 800;
  margin: 0;
  color: #2a3036;
}


.title span {
  color: var(--accent-color);
}

.grid {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;

  width: calc(100% - 40px);
  min-width: 1000px;


}

@media(max-width: 1480px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card img {
  width: 300px;
  border-radius: 5px;
  border: 1px solid #333329ad;
}

.card {
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mintcoming{
  background: var(--background);
  cursor: not-allowed;
}
button {
  position: relative;
  padding: 10px 20px;
  border-radius: 7px;
  border: 1px solid var(--accent-color);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  background: transparent;
  color: #fff;
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

button:hover {
  background: var(--accent-color);
  box-shadow: 0 0 30px 5px #7dc9ff;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

button:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  -moz-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}

button::before {
  content: '';
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

button:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}
.card h3 {
  font-size: 22px;
  text-align: center;
  margin-bottom: 0;
}

.card p {
  font-size: 20px;
  text-align: justify;
}



.timeline {
  display: flex;
  flex-direction: column;
  padding: 75px 0;
  position: relative;
}

.timeline p {
  font-size: 16px;
}

.timeline .text-part .title {
  text-align: center;
  margin-inline: auto;
}

.timeline .text-part {
  margin-inline: auto;
  margin-bottom: 50px;
}

.timeline__event {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  margin: 20px 0;
  border-radius: 6px;
  align-self: center;
  width: 65vw;
}

.timeline__event:nth-child(2n+1) {
  flex-direction: row-reverse;
}

.timeline__event:nth-child(2n+1) .timeline__event__date {
  border-radius: 0 6px 6px 0;
}

.timeline__event:nth-child(2n+1) .timeline__event__content {
  border-radius: 6px 0 0 6px;
}

.timeline__event:nth-child(2n+1) .timeline__event__icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: var(--accent-color);
  position: absolute;
  top: 100%;
  left: 50%;
  right: auto;
  z-index: 0;
  transform: translateX(-50%);
}

.timeline__event:nth-child(2n+1) .timeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--accent-color);
  position: absolute;
  right: 100%;
  z-index: 0;
  top: 50%;
  left: auto;
  transform: translateY(-50%);
}

.timeline__event__title {
  font-size: 24px;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1.5px;
}

.timeline__event__content {
  padding: 20px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  background: #101418;
  border: 1px solid var(--accent-color);
  width: calc(55vw - 84px);
  border-radius: 0 6px 6px 0;
  z-index: 2;
  transition: 0.2s all ease;
}
.timeline__event__content:hover{
    box-shadow: 0px 0px 30px 1px #a2d8ff;
}

.timeline__event__date {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  background: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 20px;
  border-radius: 6px 0 0 6px;
  z-index:2;
}

.timeline__event__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  align-self: center;
  margin: 0 20px;
  background: var(--accent-color);
  border-radius: 20%;
  width: 200px;
  border: 1px solid var(--accent-color);
  height: 200px;
  min-width: 200px;
  min-height: 200px;
  position: relative;
  object-fit: cover;
}

.timeline__event__icon img {
  width: 100%;
  height: 100%;
  border-radius: 20%;
}

.timeline__event__icon:before {
  content: "";
  width: 2px;
  height: 130%;
  background: var(--accent-color);
  position: absolute;
  top: 100%;
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
}

.timeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--accent-color);
  position: absolute;
  left: 100%;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
}

.timeline__event__description {
  flex-basis: 60%;
}

.timeline__event--type2:after {
  background: var(--accent-color);
}

.timeline__event--type2 .timeline__event__date {
  color: #fff;
  background: var(--accent-color);
}

.timeline__event--type2:nth-child(2n+1) .timeline__event__icon:before,
.timeline__event--type2:nth-child(2n+1) .timeline__event__icon:after {
  background: var(--accent-color);
}

.timeline__event--type2 .timeline__event__icon {
  background: var(--accent-color);
  color: #fff;
}

.timeline__event--type2 .timeline__event__icon:before,
.timeline__event--type2 .timeline__event__icon:after {
  background: var(--accent-color);
}

.timeline__event--type2 .timeline__event__title {
  color: #fff;
}

.timeline__event--type3:after {
  background: var(--accent-color);
}

.timeline__event--type3 .timeline__event__date {
  color: #fff;
  background-color: var(--accent-color);
}

.timeline__event--type3:nth-child(2n+1) .timeline__event__icon:before,
.timeline__event--type3:nth-child(2n+1) .timeline__event__icon:after {
  background: var(--accent-color);
}

.timeline__event--type3 .timeline__event__icon {
  background: var(--accent-color);
  color: #fff;
}

.timeline__event--type3 .timeline__event__icon:before,
.timeline__event--type3 .timeline__event__icon:after {
  background: var(--accent-color);
}

.timeline__event--type3 .timeline__event__title {
  color: #fff;
}

.timeline__event:last-child .timeline__event__icon:before {
  content: none;
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
    align-self: center;
  }

  .timeline__event__content {
    width: 100%;
  }

  .timeline__event__icon {
    border-radius: 6px 6px 0 0;
    width: 100%;
    margin: 0;
    box-shadow: none;
  }

  .timeline__event__icon:before,
  .timeline__event__icon:after {
    display: none;
  }

  .timeline__event__date {
    border-radius: 0;
    padding: 20px;
  }

  .timeline__event:nth-child(2n+1) {
    flex-direction: column;
    align-self: center;
  }

  .timeline__event:nth-child(2n+1) .timeline__event__date {
    border-radius: 0;
    padding: 20px;
  }

  .timeline__event:nth-child(2n+1) .timeline__event__icon {
    border-radius: 6px 6px 0 0;
    margin: 0;
  }
}

@-webkit-keyframes fillLeft {
  100% {
    right: 100%;
  }
}

@keyframes fillLeft {
  100% {
    right: 100%;
  }
}

@-webkit-keyframes fillTop {
  100% {
    top: 100%;
  }
}

@keyframes fillTop {
  100% {
    top: 100%;
  }
}

@-webkit-keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}

@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}
.launch{
  align-items: center;
}
.discord-banner button{
  margin-top: 40px;
  white-space: nowrap;
}

.discord-banner {
  height: 450px;
  padding: 50px 0;
}

.discord-banner-wrap {
  background: #1a1c21;
}

.discord-banner .text-part {
  width: calc(60% - 40px);
}

.discord-banner .title h3 {
  color: #414346;
}

.discord-banner img {
  height: 100%;
  object-fit: contain;
}

footer {
  padding: 50px 0;
  justify-content: space-between;
}

footer .mainlogowrap {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.336);
  text-decoration: none;
  gap: 20px;
}

footer .social-icons a {
  margin-inline: 10px;
}

.audio-player {
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 5px 5px;
  width: 60px;
  border-radius: 10px;
}

audio {
  width: 60px;
}
.flexaudi{
  display: flex;
  align-items: center;
}


.audio-player img {
  width: 50px;
  border-radius: 10px;
}

.audio-player button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

.audio-player button img {
  width: 35px;
  transition: 0.2s all ease-in-out;
}

.iconlines {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-content: flex-end;
  padding: 3px 0;
  transition: all 1s ease;
  height: 40px;
}

.iconlines>span {
  width: 3px;
  height: 100%;
  border-radius: 1px;
  margin: 0 1.5px;
  transform-origin: bottom;
  transition: opacity .3s ease-out;
  content: "";
  transition: all 1s ease;
  background: white;
}
.iconlines>span:nth-of-type(2) {
  animation-delay: -2.2s;
  transition: all 1s ease;
}
.iconlines>span:nth-of-type(3) {
  animation-delay: -3.7s;
  transition: all 1s ease;
}
.pausedicon span {
  transform: scaleY(.05);
  margin-top: auto !important;
  transition: all 1s ease;
}

.playingicon span {
  animation: bouncePlayingLines 2.2s ease infinite alternate;
  transform: scaleY(1);
  background: var(--accent-color) !important;
}
@keyframes bouncePlayingLines {
  10% {
      transform: scaleY(.25)
  }

  30% {
      transform: scaleY(.83)
  }

  60% {
      transform: scaleY(.42)
  }

  80% {
      transform: scaleY(.625)
  }

  to {
      transform: scaleY(.5)
  }
}
.team{
  padding-top: 50px;
}
.team-wrap{
  padding-top: 75px;
}
.carouseltitle{
  text-align: center;
  font-size: 40px;
  font-family: candara;
  font-weight: 800;
}
@media(max-width: 1000px){
  .carouseltitle{
    font-size: 30px;
  }
}
.slider-part {
  margin-bottom: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent !important;
  position: relative;

}

.marquee-wrapper {
  text-align: center;
}

.marquee-wrapper .container {
  overflow: hidden;
  max-width: 100%;
}

.marquee-inner span {
  float: left;
  width: 50%;
}

.marquee-wrapper .marquee-block {
  --total-marquee-items: 7;
  height: 268px;
  width: calc(278px * (var(--total-marquee-items)));

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin: 10px auto;
  margin-top: 0px;
  margin-bottom: 0px;
}

.marquee-inner {
  display: -webkit-block;
  display: -moz-block;
  display: -ms-block;
  display: block;
  width: 200%;
  position: absolute;
}

.marquee-inner.to-left {
  -webkit-animation: marqueeLeft 40s linear infinite;
  -moz-animation: marqueeLeft 40s linear infinite;
  -o-animation: marqueeLeft 40s linear infinite;
  -ms-animation: marqueeLeft 40s linear infinite;
  animation: marqueeLeft 40s linear infinite;
}

.marquee-inner.to-right {
  -webkit-animation: marqueeRight 40s linear infinite;
  -moz-animation: marqueeRight 40s linear infinite;
  -o-animation: marqueeRight 40s linear infinite;
  -ms-animation: marqueeRight 40s linear infinite;
  animation: marqueeRight 40s linear infinite;
}

.marquee-item {
  width: 250px;
  height: 250px!important;
  border: 1px solid var(--accent-color);
  background: rgba(0, 0, 0, 0.514);
  height: auto;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-block;
  margin: 0 5px;
  /* float: left; */
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  padding: 8px;
}

.marquee-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.para{
  position: absolute;
}
.frog{
  left: 0;
  top: 100px;
}
.crown{
  right: 0;
  top: 400px;
}
.crown2{
  bottom: 200px;
  left: 0;
}
.money{
  bottom: 0;
  right: 0;
}
.para img{
  width: 400px;
}

@-moz-keyframes marqueeLeft {
  0% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }

  100% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }
}

@-webkit-keyframes marqueeLeft {
  0% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }

  100% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }
}

@-o-keyframes marqueeLeft {
  0% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }

  100% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }
}

@keyframes marqueeLeft {
  0% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }

  100% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }
}

@-moz-keyframes marqueeRight {
  0% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }

  100% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }
}

@-webkit-keyframes marqueeRight {
  0% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }

  100% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }
}

@-o-keyframes marqueeRight {
  0% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }

  100% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }
}

@keyframes marqueeRight {
  0% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }

  100% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }
}
@media(max-width: 1000px) {

  .project,
  .prologue,
  .faq,
  .team,
  .discord-banner,
  footer,
  .timeline {
    width: calc(100% - 20px);
    padding: 20px 10px;
    flex-direction: column;
  }

  .project img,
  .faq img {
    width: 100%;
    height: auto;
  }

  .text-part .title h2,
  .team-wrap .title h2 {
    font-size: 60px;
    line-height: 60px;
    margin-left: 0px;
  }

  .text-part .title h3 {
    font-size: 40px;
    line-height: 20px;
  }

  .text-part {
    width: 100%;
  }
  .text-part p{
    margin: 30px 0;
  }
  .faq .text-part p{
    margin: 10px 0;
  }
  .faq .text-part .title h2{
    font-size: 50px;
  }
  .faq .text-part .title h3{
    font-size: 25px;
  }

  .prologue img, .team img {
    position: relative;
    width: 100%;
    height: auto;
    opacity: 1;
    top: auto;
    right: auto;
    border-radius: 10px;
    border: 1px solid #33292e8f;
    box-shadow: 0px 0px 50px 0px #33292e8f;

  }

  .prologue {
    flex-direction: column-reverse;
    height: auto;
  }

  .timeline__event__icon {
    background: transparent !important;
    border: 0 !important;
    height: auto !important;
    margin-bottom: 20px !important;
  }

  .timeline__event {
    width: calc(100%);
  }

  .timeline__event__content {
    width: calc(100% - 40px);
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    border-bottom-left-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
  }

  .timeline__event__date {
    padding: 0px !important;
    width: 100% !important;
    border: 1px solid var(--accent-color) !important;
  }

  .timeline__event__icon img {
    width: 200px;
    height: 200px;
    border: 1px solid var(--accent-color);
  }

  .grid {
    flex-direction: column;
    min-width: auto;
    width: 100%;
    justify-content: center;
  }

  .team {
    gap: 0px;
  }

  .card {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .card img {
    box-shadow: 0px 0px 50px 0px #33292e8f;
  }

  .discord-banner img {
    width: 100%;
  }

  .discord-banner {
    width: 100%;
    height: auto;
    padding: 20px 0px;
    padding-bottom: 0px;
    align-items: flex-start;
  }

  .discord-banner .text-part {
    padding-left: 10px;
  }

  nav {
    width: calc(100% - 20px);
    padding-inline: 10px;
  }

  .menu-button {
    padding: 10px;
    background: var(--background);
    border-radius: 10px;
    top: 18px;
  }

  .mainlogowrap {
    display: flex;
    align-items: center;
  }

  .social-icons {
    gap: 10px;
  }
  .home-top h2{
    font-size: 16px;
  }
  .smallermobi h3{
    font-size: 13px;
  }
  .home-top h1{
    font-size: 50px;
  }
  .home-top, .project, .timeline, .team-wrap, .faq, .discord-banner{
    overflow-x: hidden;
  }
  .home-top{
    background-attachment: scroll;
    background: url(./mobilehome.png);
    background-size: 100% 100%;
  }
  .imgteam{
    width: 100%;
    height: auto;
  }
  .imgteam img{
    box-shadow: none;
    border: none;
  }
  .team-wrap{
    padding-top: 20px;
  }
  .team .text-part{
    width: 100%;
  }
}
.darkbg{
  background: var(--background);
}
.spinner{
  max-width: 100%;
  width: 450px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
}
.spinner img{
  width: 100%;
  height: auto;
}
.getfav{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  font-family: candara;
  font-size: 40px;
  margin-top: 50px;
}
.partners{
  display: flex;
  justify-content: center;
  gap: 100px;
  align-items: flex-end;
  max-width: 1380px;
  margin-inline: auto;
  margin-bottom: 50px;
}
.partners img{
  width: 150px;
  height: auto;
  border-radius: 10px;
}
.partner{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.partner h3{
  font-family: candara;
}
.rounded{
  border-radius: 10px;
}
.roundedfull{
  border-radius: 50%;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-inline: auto;
}
.center .title h2{
  text-align: center;
  margin-inline: auto;
}
.center .title p{
  margin-top: 20px;
  margin-bottom: 100px;
  max-width: 90%;
  margin-inline: auto;
}
.minw{
  min-width: 257px;
}
@media(max-width: 1000px){
  .spinner{
    max-width: 90%;
  }
  .partners{
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 100%;
    width: 90%;
  }
}